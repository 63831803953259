import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { StorageService } from 'app/@core/services/storage.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private storageService: StorageService
    ) {
      // if (((localStorage.getItem('version') !== '0.0.2'))) {
      //   localStorage.clear();
      //   localStorage.setItem('version','0.0.2')
      //   window.location.href = 'https://e4score-suitefedev.azurewebsites.net/';
      //   alert("Application will logout. Login again to see latest changes")

      // }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const companyId = route.queryParams['companyId'];
    const userEmail = route.queryParams['userEmail'];
    const companyName = route.queryParams['companyName'];
    const userName = route.queryParams['userName'];
    const authToken = route.queryParams['authToken'];
    const subscriptionId = route.queryParams['subscriptionId'];
    const isMultipleSubscription = route.queryParams['isMultipleSubscription'];
    const companyUserId = route.queryParams['companyUserId'];
    const moduleId = route.queryParams['moduleId'];
    const userType = route.queryParams['userType'];
    if (authToken) {
      localStorage.setItem("companyID", companyId);
      localStorage.setItem("companyUserId", companyUserId);
      localStorage.setItem("moduleId", moduleId);
      localStorage.setItem("userType", userType);
      localStorage.setItem("userEmail", userEmail);
      this.storageService.setCompanyName(companyName);
      this.storageService.setUserName(userName);
      localStorage.setItem("authToken", authToken);
      localStorage.setItem("subscriptionId", subscriptionId);
      localStorage.setItem("isMultipleSubscription", isMultipleSubscription);
    }
    let isAuth = this.authService.isAuthenticated();
    if (!isAuth) {
      // this.router.navigate(['/login']);
      window.location.href = 'https://e4suite.e4score.com/login';
    }
    else {
      return true;
    }
  }
}
