import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appNumericInput]'
})
export class NumericInputDirective {

    @HostListener('input', ['$event']) onInput(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;
        const inputValue = input.value;

        // Remove any non-numeric characters using a regular expression
        const numericValue = inputValue.replace(/[^0-9.-]/g, '');

        if (numericValue !== inputValue) {
            input.value = numericValue;
            input.dispatchEvent(new Event('input')); // Emit an 'input' event to propagate the updated value
        }
    }

}
