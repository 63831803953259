import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { BaseService } from './services/base.service';
import { AutoUnsubscribe } from './services/auto-unscribe';
import { FormValidatorService } from './services/formValidator.service';
import { StorageService } from "app/@core/services/storage.service";
import { ResetPasswordGuard } from './services/reset-password-guard';

@NgModule({
  providers: [
    FormValidatorService,
    BaseService,
    ResetPasswordGuard,
    AutoUnsubscribe,
    StorageService
  ],
  imports: [
    CommonModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    };
  }
}
