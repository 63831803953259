import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class UtilitiesService {
    KILOMETERS_PER_MILE = 1.609344;
    public modalConfig: any = {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg'
    };
    constructor() { }
    public camelCaseToWords = (camelCase: any) => camelCase.replace(/([A-Z])/g, " $1");

    public getErrorsFromValidationError = (validationError: any) => {
        const FIRST_ERROR = 0;
        return validationError.inner.reduce((errors: any, error: any) => {
            return {
                ...errors,
                [error.path]: error.errors[FIRST_ERROR],
            }
        }, {})
    };

    public latLongDistance = (lat1: any, lon1: any, lat2: any, lon2: any, unit: any) => {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        } else {
            if (isNaN(parseFloat(lat1)) || isNaN(parseFloat(lon1)) || isNaN(parseFloat(lat2)) || isNaN(parseFloat(lon2))) return "N/A";
            var radlat1 = Math.PI * lat1 / 180;
            var radlat2 = Math.PI * lat2 / 180;
            var theta = lon1 - lon2;
            var radtheta = Math.PI * theta / 180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            if (isNaN(dist)) return "N/A";
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515; // to miles
            if (unit === "Kilometers") { dist = dist * 1.609344 }
            return dist.toFixed(1) + " " + (unit === "Kilometers" ? "km" : "mi");
        }
    };

    public padNumber = (num: any, size: any) => {
        size = size || 2;
        return ('000000000' + num).substr(-size);
    };

    public isRegularLeftClick = (e: any) => {
        return (e.button === 0 && !e.altKey && !e.shiftKey && !e.ctrlKey && !e.metaKey);
    };

    public promiseReturningEmptyList = () => () =>
        new Promise(resolve => {
            resolve([]);
        });

    public formatLatLong = (number: any) => {
        try {
            if (number) {
                return parseFloat(number).toFixed(5);
            }
        } catch (e) { }
        return "N/A";
    };

    public formatDistanceFromDomicile = (distanceFromDomicileInMeters: any, distanceUnitsOfMeasure: any) => {
        if (distanceUnitsOfMeasure === 'Miles') {
            return distanceFromDomicileInMeters ?
                new Intl.NumberFormat('en-US', { maximumFractionDigits: 1 })
                    .format(distanceFromDomicileInMeters / 1000.0 / this.KILOMETERS_PER_MILE) : { distanceUnitsOfMeasure }

        } else {
            let distanceInMeters = distanceFromDomicileInMeters;
            if (distanceInMeters != null) {
                return new Intl.NumberFormat('en-US', { maximumFractionDigits: 1 })
                    .format(distanceInMeters / 1000.0)
                //  {distanceUnitsOfMeasure}
            } else {
                return null;
            }
        }
    };

    public formatMoveFrequency = (movesInLast30Days: any) => {
        return movesInLast30Days >= 0 ?
            new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, style: 'percent' })
                .format(movesInLast30Days / 30) :
            null
    };
    public formatIdleTime = (idleTime: any) => {
        if (idleTime) {
            let d = moment.duration(idleTime);
            return new Intl.NumberFormat("en-US", { maximumFractionDigits: 1 }).format(d.asDays());
        } else {
            return null;
        }
    }

    getLocalStorageName(pageName: any, user: any) {
        let s = (user ? user.email + "-" : "") + pageName;
        return s;
    }

    saveTableState(pageName: any, state: any, user: any) {
        localStorage.setItem(this.getLocalStorageName(pageName, user), JSON.stringify({
            previousSearchRequest: state.previousSearchRequest,
            page: state.page,
            pageSize: state.pageSize,
            pages: state.pages,
            autoRefreshEnabled: state.autoRefreshEnabled,
            itemsPerPage: state.itemsPerPage,
            sorted: state.sorted,
            filtered: state.filtered,
            hideFilters: state.hideFilters,
            operator: state.operator ? state.operator : null,
            columns: state.columns,
            enabledNotification: state.enabledNotification
        }))
    }

    getDwellForAssetType(assetType: any, tractorDwell: any, trailerDwell: any) {
        if (assetType === "Trailer") {
            return trailerDwell;
        } else if (assetType === "Tractor") {
            return tractorDwell;
        }
    }
    convertCtoF(tempInC: any) {
        if (tempInC == null) {
            return null;
        }
        return tempInC * 9 / 5 + 32;
    }
    convertFtoC(tempInF: any) {
        if (tempInF == null) {
            return null;
        }
        return (tempInF - 32) * 5 / 9;
    }

    startOfDay(date: any, days: any) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        if (days) {
            date = date.setDate(date.getDate() - days);
            date = new Date(date);
            date = date.toISOString();
            return date;
        } else {
            return date;
        }
    }

    endOfDay(date: any, days: any) {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        date.setMilliseconds(999);
        if (days) {
            date = date.setDate(date.getDate() + days);
            date = new Date(date);
            date = date.toISOString();
            return date;
        } else {
            return date;
        }
    }

    getTableSize(pageElementId: any, pageHeaderElementId: any) {
        let w = window.innerWidth - 16;
        let h = window.innerHeight - 183.5; // 67.5 for filter row above table, 70 for table footer, 29 for table header, 17 for horizontal scroll bar
        let abh = document.getElementById('pft-action-bar-header');
        if (abh !== null) {
            h = window.innerHeight - abh.offsetHeight - 2;
        }
        let abf = document.getElementById('pft-action-bar-footer');
        if (abf !== null) {
            h = h - abf.offsetHeight;
        }
        let d = document.getElementById(pageElementId);
        if (d === null) {
            return { height: "500px", width: "500px" }
        }
        let f = document.getElementById("ez-app-footer");
        if (f !== null) {
            h = h - f.offsetHeight;
        } else {
            h = d.offsetHeight - 75;
        }
        let hdr = document.getElementById("ez-app-header");
        if (hdr !== null) {
            h = h - hdr.offsetHeight;
        } else {
            h = h - 55;
        }
        let shipmentsHeader = document.getElementById(pageHeaderElementId);
        if (shipmentsHeader !== null) {
            h = h - shipmentsHeader.offsetHeight;
        }
        return { height: h, width: w };
    }

    convertShapeJsonToMapObject(shape: any, map: any, google: any) {
        if (shape.geometry.type === "Point") {
            return new google.maps.Circle({
                map,
                center: { lat: shape.geometry.coordinates[1], lng: shape.geometry.coordinates[0] },
                radius: shape.properties.radius,
            });
        } else if (shape.geometry.type === "Polygon") {
            let coords: any = [];
            shape.geometry.coordinates[0].map((coord: any) => coords.push({ lat: coord[1], lng: coord[0] }));
            return new google.maps.Polygon({
                map,
                paths: coords,
            });
        }
    }

    milesToMeters(miles: any) {
        return miles * 1609.34;
    }

}
