import { environment } from './../../../environments/environment';
import { Observable, of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { StorageService } from "app/@core/services/storage.service";
import { saveAs } from 'file-saver';

@Injectable()
export class BaseService {
    private readonly STORAGE_KEY = 'table-settings';
    reloadCoulmn = new Subject();
    gridCount = new Subject();
    tableData = new Subject();
    tableData$ = this.tableData.asObservable();
    public filterSelect2Options = [];




    public createE4ScoreRoutePath(parentPath: string, completePath: string): any {
        const moduleId = localStorage.getItem('moduleId');
        const subscriptionId = localStorage.getItem('subscriptionId');
        const userType = localStorage.getItem("userType");

        if (userType === "Company User") {
            const companyUserId = localStorage.getItem("companyUserId");
            return this.getRequest(`accessspecification/GetCompanyUserAccessSpecificationsByModuleId?UserId=${companyUserId}&ModuleId=${moduleId}`).pipe(
                map(res => {
                    const functionList = res.data;
                    const ezTrackModule = functionList?.find(module => module.moduleId == moduleId);
                    const ezTrackParent: any = ezTrackModule?.parents;

                    const parentFeature = ezTrackParent?.find(parent => parent.featurePath === parentPath);
                    const features: any = parentFeature?.features;
                    const featureFuncs = features?.find((path) => path.featurePath == completePath);
                    const funcs = featureFuncs?.functions;

                    return funcs || [];
                })
            );
        } else {
            return this.getRequest(`accessspecification/GetAccessSpecificationsBySubscriptionId?SubscriptionId=${subscriptionId}`).pipe(
                map(res => {
                    const functionList = res.data;
                    const ezTrackModule = functionList?.find(module => module.moduleId == moduleId);
                    const ezTrackParent: any = ezTrackModule?.parents;
                    const parentFeature = ezTrackParent?.find(parent => parent.featurePath === parentPath);
                    const features: any = parentFeature?.features;
                    const featureFuncs = features?.find((path) => path.featurePath == completePath);
                    const funcs = featureFuncs?.functions;
                    return funcs || [];
                })
            );
        }
    }

    setData(data: string) {
        this.tableData.next(data);
    }

    getTableData(): Observable<any> {
        return this.tableData.asObservable();
    }

    constructor(
        private http: HttpClient,
        private spinner: NgxSpinnerService,
        public storageService: StorageService

    ) { }

    public sendForgotPassword(url): Observable<any> {
        return this.http.post(environment.APIURL + url, {});
    }

    public postRequest(url, body): Observable<any> {

        const tableSettingKey = localStorage.getItem('tableSettingKey');
        let isLoginCall = url.includes('login')
        if (!isLoginCall) {
            let parseData = JSON.stringify(body);
            localStorage.setItem(tableSettingKey, parseData);
        }
        return this.http.post(environment.APIURL + url, body);
    }

    public getRequest(URL: any): Observable<any> {
        return this.http.get(environment.APIURL + URL);
    }

    public putRequest(url, body): Observable<any> {
        return this.http.put(environment.APIURL + url, body);
    }

    public removeRequest(url, UserId?): Observable<any> {
        return this.http.delete(environment.APIURL + url);
    }

    public exportExcel(URL: any, postData): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(environment.APIURL + URL, postData, { headers, responseType: 'blob' })
    }

    public importExcel(URL: any, postData): Observable<any> {
        return this.http.post(environment.APIURL + URL, postData)
    }

    public getMapData(PageNumber, assetType = 0, assetList, optionalPageSize): Observable<any> {
        const companyId = this.storageService.getCompanyID();
        const url = `MapData`
        let obj = {
            "companyId": companyId,
            "assetType": assetType,
            "pageNumber": PageNumber,
            "pageSizeOptional": optionalPageSize,
            "assetList": assetList
        }
        return this.http.post(environment.APIURL + url, obj);
    }

    userId = localStorage.getItem('companyUserId');

    public getTableSettings1(pageReference): Observable<any> {
        const url = `filter?userId=${this.userId}&page=${pageReference}`;
        return this.http.get(environment.APIURL + url);
    }

    getTableSettings(pageReference, isHardReload): Observable<any> {
        const tableSettingKey = localStorage.getItem('tableSettingKey');
        const cachedData = localStorage.getItem(pageReference + '-' + this.STORAGE_KEY);
        let userType = localStorage.getItem("userType");
        let userId: any;
        if (userType === "Company User") {
            userId = localStorage.getItem("companyUserId");
        }
        else {
            userId = null;
        }
        const companyId = localStorage.getItem('companyID')
        if (cachedData && isHardReload) {
            return of(JSON.parse(cachedData));
        } else {
            const url = `filter?userId=${userId}&page=${pageReference}&companyId=${companyId}`;
            return this.http.get(environment.APIURL + url)
                .pipe(
                    map((response: any) => {
                        localStorage.setItem(pageReference + '-' + this.STORAGE_KEY, JSON.stringify(response));
                        let parseData = response.filterJson;
                        localStorage.setItem(pageReference + "-filterJSON", parseData);
                        return response;
                    }),
                    catchError(error => of(error))
                );
        }
    }

    public postTableSettings(tableSettingKey, body): Observable<any> {
        localStorage.removeItem(tableSettingKey + '-' + this.STORAGE_KEY);
        return this.http.post(environment.APIURL + 'filter', body);
    }

    public getLocalTableSettings(tableSettingKey): string {
        return localStorage.getItem(tableSettingKey + '-' + this.STORAGE_KEY);
    }

    public showLoader() {
        this.spinner.show();
    }
    public hideLoader() {
        this.spinner.hide();
    }

    filterDeviceModel(value: string, optionsArr): any[] {
        if (!value) {
            return optionsArr;
        }
        const lowerCaseValue = value.toLowerCase();
        return optionsArr.filter(option => option.deviceType.toLowerCase().includes(lowerCaseValue));
    }

    filterOptions(value: string, optionsArr): any[] {
        if (!value) {
            return optionsArr;
        }
        const lowerCaseValue = value.toLowerCase();
        return optionsArr.filter(option => option.name.toLowerCase().includes(lowerCaseValue));
    }

    filterOption(value: string, optionsArr): any[] {
        if (!value) {
            return optionsArr;
        }
        const stringValue = value.toLowerCase();
        return optionsArr.filter(option => option.imei.toLowerCase().includes(stringValue));
    }

    downloadTemplate(fileUrl, savedName) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', fileUrl, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
            if (xhr.status === 200) {
                const blob = new Blob([xhr.response], { type: 'application/octet-stream' });
                saveAs(blob, savedName);
            }
        };
        xhr.send();
    }
}