import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseService } from 'app/@core/services/base.service';
import { StorageService } from "app/@core/services/storage.service";
import { pipe } from 'rxjs';
import { finalize, mergeMap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'column-manager',
  styleUrls: ['./column-manager.component.scss'],
  templateUrl: './column-manager.component.html',
})
export class ColumnManagerterComponent implements OnInit {
  @Output() changed = new EventEmitter();
  @Output() reload = new EventEmitter();
  @Output() closeModalEvent = new EventEmitter();

  @Input() menuColumns;
  @Input() tableSettingKey = '';
  isChangedAnyThing = false;
  columnObj: any = {
    userId: localStorage.getItem('companyUserId'),
    json: [],
    type: 'Custom',
    page: '',
    companyId: this.storageService.getCompanyID()
  }
  isAllSelected = false;
  constructor(
    public requestService: BaseService,
    public storageService: StorageService,
  ) {
  }
  ngOnInit(): void {
    this.menuColumns = JSON.parse(this.menuColumns);
    this.isAllSelected = this.isAllselectSlected();
  }

  toggleColumn(columnItem) {
    this.isChangedAnyThing = true;
    columnItem['isShowColumn'] = !columnItem['isShowColumn'];
    this.isAllSelected = this.isAllselectSlected();
  }

  isAllselectSlected() {
    return this.menuColumns.every(item => item.isShowColumn)
  }


  selectAll(event) {
    this.isAllSelected = event.target.checked;
    this.isChangedAnyThing = true;
    this.menuColumns.map(item => {
      item.isShowColumn = event.target.checked;
      return item;
    })
  }

  applyColumn() {
    this.closeModal();
    this.reload.emit(this.menuColumns);
    this.columnObj.page = this.tableSettingKey;
    this.menuColumns.map(it => delete it.cellTemplate);
    this.columnObj.json = this.menuColumns;
    this.saveColumnsData(this.columnObj);
  }

  isRestoring: boolean = false;
  
  resetDefault() {
    this.isRestoring = true;
    this.requestService.getTableSettings(this.tableSettingKey, false).pipe(
      finalize(() => {
        this.isRestoring = false;
      })
    ).subscribe(response => {
      this.menuColumns = JSON.parse(response.defaultJson);
    });
    this.isAllSelected = this.isAllselectSlected();
    this.requestService.getTableSettings(this.tableSettingKey, true).pipe(
      finalize(() => {
        this.isRestoring = false;
      })
    ).subscribe((response) => {
      this.menuColumns = JSON.parse(response.defaultJson);
      this.isAllSelected = this.isAllselectSlected();
    })
  }


  drop(event: CdkDragDrop<string[]>) {
    this.isChangedAnyThing = true;
    moveItemInArray(this.menuColumns, event.previousIndex, event.currentIndex);
  }

  saveColumnsData(columnObj) {
    this.requestService.postTableSettings(this.tableSettingKey, columnObj)
      .subscribe(() => {
        this.closeModal();
        this.requestService.reloadCoulmn.next({ 'hard-reload': true });
      }, (error) => {
        console.log(error);
      });
  }

  closeModal() {
    this.closeModalEvent.emit();
  }

}
