<!--Footer Starts-->
<footer class="footer">
  <p class="copyright clearfix text-muted m-0">
    <span class="float-left ">
      Copyright &copy; {{currentDate | date: 'yyyy'}} <a href="https://www.e4score.com/" target="_blank">IGIT
        ENTERPRISES, INC. D/B/A E4SCORE.COM</a>
      <span class="d-none d-sm-inline-block">, ALL RIGHTS RESERVED.</span>
    </span>
    <span class="float-right ">Powered by
      <a href="https://www.e4score.com/eztrack-trailer-and-asset-tracking/" target="_blank">e4score</a>
    </span>
  </p>
</footer>
<!--Footer Ends-->