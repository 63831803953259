import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable()
export class ResetPasswordGuard implements CanActivate {
    constructor(
        private router: Router,
        private toastr: ToastrService,
        private baseService: BaseService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const token = route.params.token;

        return this.baseService.sendForgotPassword('user/validateforgotpasswordtoken?token=' + token).pipe(
            map((isValid: boolean) => {
                if (!isValid) {
                    this.toastr.error('Invalid reset password link');
                    this.router.navigate(['/login']);
                    return false;
                }

                return true;
            }),
            catchError(() => {
                this.toastr.error('Invalid reset password link');
                this.router.navigate(['/login']);
                return of(false);
            })
        );
    }
}
