import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

  constructor() { }

  getCompanyID(): any {
    return +localStorage.getItem('companyID');
  }

  setCompanyID(value: any): void {
    localStorage.setItem('companyID', value);
  }

  setCompanyName(value: any): void {
    localStorage.setItem('companyName', value);
  }

  setUserName(value: any): void {
    localStorage.setItem('userName', value);
  }
  
  setUserEmail(value: any): void {
    localStorage.setItem('userEmail', value);
  }

  getUserEmail(): any {
    return localStorage.getItem('userEmail');
  }

  getUserName(): any {
    return localStorage.getItem('userName');
  }

  setRoles(value: any): any {
    return localStorage.setItem('roleName', value);
  }

  getRoles(): any {
    return localStorage.getItem('roleName');
  }

  getCompanyName(): any {
    return localStorage.getItem('companyName');
  }

  setEnableAssetPool(value: any): void {
    localStorage.setItem('isEnableAssetPool', value);
  }

  isEnableAssetPool(): boolean {
    const enable = localStorage.getItem('isEnableAssetPool');
    // for now we want to enable for all
    return true;
    //  enable ? JSON.parse(enable) : false;
  }
}
