import { StorageService } from 'app/@core/services/storage.service';
import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from "@angular/core";
import { ROUTES } from './vertical-menu-routes.config';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { BaseService } from 'app/@core/services/base.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  role = this.storageService.getRoles();
  functionList: any[] = [];
  moduleId;
  isLoading: boolean = false;

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private storageService: StorageService,
    public baseService: BaseService,
    private toastr: ToastrService,
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }

  enableMenu = {
    '/map-view': true,
    '/tracked-assets': true,
    '/track-history': true,
    '/asset-dwell-time': true,
    '/asset-pools': true,
    '/eztrack-settings': true,
    '/access-management':true
  };
  parentFeatures: any[] = [];
  isMultipleSubscription;
  userType
  ngOnInit() {
    this.isMultipleSubscription = localStorage.getItem("isMultipleSubscription")
     this.userType = localStorage.getItem("userType");
     
    if(this.userType === "Company User"){
      const companyUserId = localStorage.getItem("companyUserId");
      const moduleId = localStorage.getItem("moduleId");

      this.baseService.getRequest(`accessspecification/GetCompanyUserAccessSpecificationsByModuleId?UserId=${companyUserId}&ModuleId=${moduleId}`)
      .subscribe(res => {
        localStorage.removeItem("moduleId")

        this.isLoading = true;
                this.functionList = res.data;
                this.moduleId = res.data[0].moduleId
                const dataConvert = JSON.stringify(res.data);
                localStorage.setItem("featureFunctionList", dataConvert);
                localStorage.setItem("moduleId", res.data[0].moduleId);
                this.menuItems = this.filterEnableMenu();
        
      })
    }else{
      const subscriptionId = localStorage.getItem("subscriptionId")
      this.baseService.getRequest(`accessspecification/GetAccessSpecificationsBySubscriptionId?SubscriptionId=${subscriptionId}`)
              .subscribe(
                res => {
                if (res.statusCode == 200) {
                  this.isLoading = true;
                  this.functionList = res.data;
                  this.moduleId = res.data[0].moduleId
                  const dataConvert = JSON.stringify(res.data);
                  localStorage.setItem("featureFunctionList", dataConvert);
                  localStorage.setItem("moduleId", res.data[0].moduleId);
                  this.menuItems = this.filterEnableMenu();
                  }
                },
                error => {
                  this.toastr.error(`Something went wrong.`, 'Opps!');
                  console.log(error)
                })
    }
  }

  filterEnableMenu() {
    this.enableMenu['/asset-pools'] = this.storageService.isEnableAssetPool();
    let menuRoutes: any[] =  [];
  menuRoutes = ROUTES.filter(route => this.enableMenu[route.path]);
if (this.functionList && this.functionList.length > 0) {
    const ezTrackModule = this.functionList.find(module => module.moduleId == this.moduleId);
    this.parentFeatures = ezTrackModule.parents;
    const ln = this.parentFeatures.length;
    const newBar: any[] = [];
    for (let x = 0; x < menuRoutes.length; x++) {
      for (let y = 0; y < ln; y++) {
        let features: any[] = [];
        let submenu: any[] = [];
        features = this.parentFeatures[y].features;
        submenu = menuRoutes[x].submenu;
        if (menuRoutes[x].path === this.parentFeatures[y].featurePath) {
          if (submenu.length) {
            let subF: any[] = [];
            for (let s = 0; s < submenu.length; s++) {
              for (let f = 0; f < features.length; f++) {
                if (features[f].featurePath === submenu[s].path) {
                  subF.push({ ...submenu[s] });
                }
              }
            }
            menuRoutes[x] = { ...menuRoutes[x], submenu: [...subF] };
          }
          newBar.push(menuRoutes[x]);
        }
      }
    }
    return newBar;
  } else {
    return menuRoutes;
  }
  }

  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();
    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }).bind(this), 500);
  }

  loadLayout() {
    if (this.config.layout.menuPosition === "Top") { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = HROUTES;
      }
    }
    else if (this.config.layout.menuPosition === "Side") { // Vertical Menu{
      this.menuItems = this.filterEnableMenu();
    }

    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/logo.png';
    }
    else {
      let conf = this.config;
      if (conf.layout.sidebar.collapsed) {
        this.logoUrl = 'assets/img/logos/short-logo.svg';
      } else {
        this.logoUrl = 'assets/img/logos/logo-visibility.png';

      }
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    }
    else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    if (conf.layout.sidebar.collapsed) {
      this.logoUrl = null;
    } else {
      this.logoUrl = 'assets/img/logo.png';

    }
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }

  }

  showHide(){
    if (this.role === 'Employee') {
      let arr = this.menuItems.filter(item => item.title !== "Settings" && item.title !== "User Access");
      this.menuItems = arr;
    }
  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

}
