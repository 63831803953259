<container-element [ngSwitch]="column.filterConfig.filterType">
  <ng-container *ngSwitchCase="'text'">
    <input [formControl]="textSearchControl" [placeholder]="column.placeholder" type="text" class="form-control"
      (keyup)="onKeyUp($event,column)" />
  </ng-container>
  <ng-container *ngSwitchCase="'date'">
    <input type="date" class="form-control" [placeholder]="column.placeholder"
      (change)="applyFilter($event.target.value)" />
  </ng-container>
  <ng-container *ngSwitchCase="'select'">
    <select [formControl]="matMultiSelectControl" class="form-control" [placeholder]="column.placeholder || ''" (change)="applyFilter($event.target.value)">
      <option [value]="opt?.id" *ngFor="let opt of column.filterConfig.selectOptions">
        {{opt?.value}}
      </option>
    </select>
  </ng-container>
  <ng-container *ngSwitchCase="'select2'">
    <select [formControl]="matMultiSelectControl" class="form-control" (change)="applySelect2Filter($event.target.value,column)">
      <option hidden disabled selected>Select an option</option>
      <option value="">All</option>
      <option [value]="opt?.id" *ngFor="let opt of column['$filteredOptions$'] | async">
        {{opt?.value}}
      </option>
    </select>
  </ng-container>
  <ng-container *ngSwitchCase="'matAutocomplete'">
    <input type="text" class="form-control" [placeholder]="column.placeholder" aria-label="Number" matInput
      [formControl]="filterControl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="autoCompleteSelected($event)">
      <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
      <mat-option *ngIf="!isLoading && !nrecod" class="is-loading">No
        result
      </mat-option>
      <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
        {{ option.value }}
      </mat-option>
    </mat-autocomplete>
  </ng-container>
  <ng-container *ngSwitchCase="'multiple'">
    <mat-select (closed)="onPanelClose()" (optionSelectionChanges)="selectionChange($event)" #select1
      class="form-control" [formControl]="matMultiSelectControl" multiple [placeholder]="column.placeholder || ''">

      <input class="form-control cust-width" matInput [formControl]="filterControl" type="text" name="filter-options"
        id="filter-options" placeholder="Search...">

      <mat-select-trigger>
        {{matMultiSelectControl.value?.[0]?.value || ''}}
        <span *ngIf="(matMultiSelectControl.value?.length || 0) > 1">
          (+{{(matMultiSelectControl.value?.length || 0) - 1}} {{matMultiSelectControl.value?.length === 2 ? 'other' :
          'others'}})
        </span>
      </mat-select-trigger>
      <mat-option class="border-bottom" id="loading" *ngIf="isLoading" class="is-loading">Loading...</mat-option>
      <mat-option class="border-bottom" id="nrecod" *ngIf="!isLoading && !nrecod" class="is-loading">No
        result
      </mat-option>
      <mat-option class="border-bottom" *ngFor="let option of filteredOptions$ | async" [value]="option">
        {{ option.value }}
      </mat-option>
    </mat-select>
  </ng-container>
</container-element>