import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import 'moment-timezone';
@Pipe({
  name: 'displayDateWithTimezone'
})
export class DisplayDateWithTimezonePipe implements PipeTransform {
  transform(utcDate: string, withtime: string): any {
    let dateTime
    if (!utcDate) {
      return "";
    }
    let timeZone = '';

    if (withtime === 'localTimeZone') {
      let dateTime = moment.utc(utcDate).local().format('MM/DD/YYYY h:mm A z');
      const localtz = moment.tz.guess();
      const shortTimeZoneName = moment.tz(localtz).zoneAbbr();
      dateTime += ` ${shortTimeZoneName}`;
      return dateTime;
    }
    dateTime = moment.tz(utcDate, timeZone).local().format('MM/DD/YYYY');
    if (timeZone) {
      const shortTimeZoneName = moment.tz(timeZone).zoneAbbr();
      dateTime += ` ${shortTimeZoneName}`;
    }
    return dateTime;
  }
}
