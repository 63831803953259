import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

  {
    path: '/map-view', title: 'Map', icon: 'ft-map', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/tracked-assets', title: 'Tracked Assets', icon: 'ft-layers', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/asset-dwell-time', title: 'Dwell Time', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/asset-pools', title: 'Asset Pools', icon: 'ft-truck', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/track-history', title: 'Track History', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/eztrack-settings', title: 'Settings', icon: 'ft-settings', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {
        path: '/eztrack-settings/partners', title: 'Partners', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/eztrack-settings/locations', title: 'Locations', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/eztrack-settings/asset-types', title: 'Asset Types', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/eztrack-settings/installed-devices', title: 'Installed Devices', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/eztrack-settings/asset', title: 'Assets', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/eztrack-settings/devices', title: 'Devices', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]
  },

  {
    path: '/access-management', title: 'User Access', icon: 'ft-user', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {
        path: '/access-management/designations', title: 'Designations', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/access-management/employees', title: 'User Profiles', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/access-management/roles', title: 'Roles', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/access-management/users', title: 'Logins', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/access-management/operating-unit', title: 'Operating-Unit', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/access-management/division', title: 'Division', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]
  },
];
